<template>
  <div class="body_section">
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
      ref="profile_hrader"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              class="search"
              type="text"
              v-model="serch_title"
              @keyup="serchTitle()"
              :placeholder="
                userdata.roleId == 5
                  ? 'Search by State'
                  : 'Search by business name and state'
              "
            />
          </div>
          <div class="d-flex">
            <filingsFilters
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              :filterStatus="filterStatus"
              :selectedGlobalClient="selectedGlobalClient"
              :recordsCount="recordsCount"
              @fileExport="fileExport"
            ></filingsFilters>
          </div>
        </div>
        <div class="list_table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" v-if="userdata.roleId != 5">
                  <a
                    @click="sortMe('clientName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['clientName'] == 1,
                      sort_descending: sortKeys['clientName'] != 1,
                    }"
                    >Business Name</a
                  >
                </th>
                <th scope="col">
                  <a
                    @click="sortMe('stateName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['stateName'] == 1,
                      sort_descending: sortKeys['stateName'] != 1,
                    }"
                  >
                    State
                  </a>
                </th>
                <th scope="col" class="text-center">Period</th>
                <th scope="col">Tax</th>
                <th scope="col">
                  <a
                    @click="sortMe('dueDate')"
                    v-bind:class="{
                      sort_ascending: sortKeys['dueDate'] == 1,
                      sort_descending: sortKeys['dueDate'] != 1,
                    }"
                    >Due Date</a
                  >
                </th>

                <th scope="col">Paid Amount</th>

                <th scope="col">
                  <a
                    @click="sortMe('filedDate')"
                    v-bind:class="{
                      sort_ascending: sortKeys['filedDate'] == 1,
                      sort_descending: sortKeys['filedDate'] != 1,
                    }"
                    >Filed On</a
                  >
                </th>
                <th scope="col">Return</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in list">
                <tr :key="index">
                  <td v-if="userdata.roleId != 5">
                    <figure @click="setGloalClient(item)">
                      <span>
                        <img
                          @error="setAltImg($event)"
                          :src="checkProperty(item, 'clientDetails', 'logo')"
                        />
                      </span>

                      <figcaption style="cursor:pointer">
                        {{ checkProperty(item, "clientDetails", "busName") }}
                      </figcaption>
                    </figure>
                  </td>
                  <td>
                    {{ checkProperty(item, "stateDetails", "name") }}
                  </td>
                  <td class="text-center ln18">
                    {{ item.filingStartDate | formatDate }} <br />-<br />
                    {{ item.filingEndDate | formatDate }}
                  </td>
                  <td>{{ item["amount"].toFixed(2) | currency }}</td>
                  <td>
                    <template v-if="checkProperty(item, 'dueDate') != '--'">
                      {{ checkProperty(item, "dueDate") | formatDate }}
                    </template>
                  </td>
                  <td>
                    <template v-if="checkProperty(item, 'filedAmount') != '--'">
                      {{
                        checkProperty(item, "filedAmount").toFixed(2) | currency
                      }}
                    </template>
                    <template v-else>
                      --
                    </template>
                  </td>
                  <td>
                    <template v-if="checkProperty(item, 'filedDate') != '--'">
                      {{ checkProperty(item, "filedDate") | formatDate }}
                    </template>
                    <template v-else>
                      --
                    </template>
                  </td>
                  <td>
                    <router-link
                      v-if="item.statusId < 2 && userdata.roleId != 5"
                      :to="'/upload-returns/' + item._id"
                      class="upload_return"
                      ><b-icon icon="upload" aria-hidden="true"></b-icon
                      >Upload</router-link
                    >
                    <span v-if="item.statusId >= 2" class="d-block">
                      <a
                        :id="'remarks1' + index"
                        class="download_form"
                        @click="download_file(item['documents'][0])"
                        ><b-icon icon="download" aria-hidden="true"></b-icon
                        >Download</a
                      >

                      <b-tooltip :target="'remarks1' + index">
                        <p v-if="UpdateAdjustMentAmount(item) != 0">
                          Difference Amount: {{ UpdateAdjustMentAmount(item) }}
                        </p>
                        <p v-if="checkProperty(item, 'comment') != '--'">
                          {{ checkProperty(item, "comment") }}
                        </p>
                      </b-tooltip>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="showLoading">
            <span class="no_data list_loader"
              >Loading<img src="@/assets/images/loader.gif"
            /></span>
          </template>

          <template v-if="list.length <= 0 && loaded && !showLoading">
            <span class="no_data">No Data!</span>
          </template>
        </div>

        <div class="pagination_wrap">
          <div v-show="list.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="false"
                :preserve-search="false"
                v-model="perPage"
                :options="perPeges"
              >
                <template
                  v-if="[10, 25, 50, 75, 100].indexOf(option) > -1"
                  slot="singleLabel"
                  slot-scope="{ option }"
                >
                  {{ option }}
                </template>
              </multiselect>
            </div>
          </div>

          <b-pagination
            v-if="list.length > 0"
            v-model="page"
            :total-rows="totalPages"
            :per-page="perPage"
            pills
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

import Multiselect from "vue-multiselect-inv";
import filingsFilters from "@/views/filters/transactionsFilters.vue";
import _ from "lodash";
import axios from "@/axios.js";

export default {
  name: "notes",
  methods: {
    fileExport(filterData) {
      document.cookie = "accessToken=" + this.userdata["accessToken"];

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      this.filterData.dueDateRange = filterData.dueDateRange;
      this.filterData.filedOnRange = filterData.filedOnRange;
      let APIURL = axios.defaults["baseURL"];

      let postData = {
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };

      this.$store
        .dispatch("getExportFilePatch", {
          data: postData,
          path: "/filings/export",
        })
        .then((response) => {
          this.exportData = response;
          //alert(JSON.stringify(APIURL+this.exportData['path']));
          //window.location.href = APIURL+"viewfile?path="+this.exportData['path'];
          window.open(
            APIURL + "viewfile?path=" + this.exportData["path"],
            "_blank"
          );
          //viewfile?path=
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    changedGlobalclient(cl) {
      this.selectedGlobalClient = cl;
      this.filterStatus = false;
      this.filterStatus = true;

      //this.$refs.rout_view.serchTitle(cl);
    },

    getList() {
      this.loaded = false;
      let postData = {
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      postData["filters"]["title"] = this.serch_title;

      if (!this.callfromSort) this.list = [];
      this.callfromSort = false;

      this.$store
        .dispatch("getList", { data: postData, path: "/filings/list" })
        .then((response) => {
          this.loaded = true;
          this.list = response.list;
          this.recordsCount = this.list.length;
          // alert(this.recordsCount);
          this.totalPages = response.totalCount;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    applyFilters(filterData) {
      //this.filterData = filterData: { "title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": []},

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      this.filterData.dueDateRange = filterData.dueDateRange;
      this.filterData.filedOnRange = filterData.filedOnRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      (this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        filedOnRange: [],
        dueDateRange: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.callfromSort = true;
        this.getList();
      }
    },
    UpdateAdjustMentAmount(item) {
      if (_.has(item, "filedAmount") && _.has(item, "amount")) {
        let dt = (item["amount"] - item["filedAmount"]).toFixed(2);
        return dt;
      } else {
        return 0;
      }
    },
    setGloalClient(item) {
      if (this.userdata["roleId"] != 5) {
        console.log("item", item);
        try {
          let cl = {
            _id: item.clientDetails["_id"],
            name: item.clientDetails.busName,
          };
          //alert(JSON.stringify(cl));
          this.$store.dispatch("globalClient", cl);
          localStorage.setItem("selectedGlobalClient", JSON.stringify(cl));
          this.$refs.profile_hrader.setGlClient(cl);
          setTimeout(() => {
            this.$router.push("/dashboard");
          }, 1);
        } catch (err) {
          this.log(err);
        }
      }
    },
  },
  data() {
    return {
      loaded: false,
      recordsCount: 0,
      callfromSort: false,
      selectedGlobalClient: null,
      filterStatus: true,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        filedOnRange: [],
        dueDateRange: [],
      },
      serch_title: "",
      sortKeys: {
        stateName: 1,
        clientName: 1,
        createdOn: -1,
        filedDate: 1,
      },
    };
  },
  components: {
    filingsFilters,
    Multiselect,
    profileHeader,
    footerView,
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "dueDate", order: -1 };

    this.sortKeys = {
      stateName: 1,
      clientName: 1,
      createdOn: 1,
      dueDate: -1,
      filedDate: 1,
    };

    this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
    this.changedGlobalclient(this.selectedGlobalClient);
    //this.filterData.clientIds
    if (
      this.selectedGlobalClient != null &&
      _.has(this.selectedGlobalClient, "_id")
    ) {
      this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
    }
    this.getList();
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>
